import React, {createContext, FC, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faSave, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../form/Button";

export const ModalProvider: FC<{children: ReactNode}> = ({children}) => {
  return <ModalProviderContextProvider>
    <ModalOverlay />
    {children}
  </ModalProviderContextProvider>
}

export function useModal(modal: Modal) {
  const modalContext = useContext(ModalProviderContext)

  const open = useCallback((): void => {
    modalContext.open(modal)
  }, [modal, modalContext.open])

  return {open}
}

type Modal = {
  title: string,
  body: ReactNode
  size?: 'md'|'lg'|'xl'
}|null

interface ModalProviderContextType {
  modal: Modal
  close: () => void
  open: (modal: Modal) => void
}

const ModalProviderContext = createContext({} as ModalProviderContextType)

const ModalProviderContextProvider: FC<{children: ReactNode}> = ({children}) => {
  const [modal, setModal] = useState<Modal>(null)
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setModal(null)
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, []);

  const close = useCallback(() => {
    setModal(null)
  }, [])

  const open = useCallback((modal: Modal) => {
    setModal(modal)
  }, [])

  return <ModalProviderContext.Provider value={{
    modal,
    close,
    open,
  }}>{children}</ModalProviderContext.Provider>
}

const ModalOverlay: FC = () => {
  const modalContext = useContext(ModalProviderContext)
  if (modalContext.modal === null) {
    return <></>
  }
  const size = modalContext.modal.size ?? 'md'
  const width = {
    'md': 'md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4',
    'lg': 'md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/3',
    'xl': 'md:w-full lg:w-2/3 xl:w-2/3 2xl:w-1/2',
  }[size]
  return <div className={"fixed inset-0 z-50 h-[100vh] w-[100vw] bg-[#000A] flex flex-col items-center justify-center"}>
    <div className={"fixed z-[-1] h-[100vh] w-[100vw]"} onClick={() => modalContext.close()}></div>
    <div className={`h-full md:h-auto w-full ${width} bg-white rounded shadow`}>
      <header className={"h-12 flex items-center justify-between px-4 border-b border-slate-100"}>
        <span className={"font-medium text-brand-900"}>{modalContext.modal.title}</span>
        <button className={"h-10 w-10 -mr-3 hover:bg-slate-100 rounded"} onClick={() => modalContext.close()}><FontAwesomeIcon icon={faClose} /></button>
      </header>
      <main className={"max-h-[calc(100vh-8rem)] overflow-y-auto"}>
        <div className={'mx-4 mt-3 '}>
          {modalContext.modal.body}
        </div>
      </main>
    </div>
  </div>
}

export const ModalFooter: FC<{text: string, icon?: IconDefinition, onSubmit?: () => void|Promise<void>}> = props => {
  const {close} = useContext(ModalProviderContext)
  const [isSaving, setIsSaving] = useState(false)
  const clickButton = useCallback(() => {
    const promise = props.onSubmit?.()
    if (promise) {
      setIsSaving(true)
      promise.then(() => {
        setIsSaving(false)
        close()
      }).catch((e) => {
        console.warn('Modal promise rejected', e)
        setIsSaving(false)
      })
    }
  }, [props.onSubmit])

  return <footer className={"-mx-4 px-4 mt-3 py-3 border-t border-slate-100 flex items-center space-x-4"}>
    <Button type={'primary'} disabled={isSaving} size={'md'} text={isSaving ? 'Opslaan...' : props.text} icon={props.icon ?? faSave} onClick={clickButton} />
    <Button type={'secondary'} size={'md'} text={'Terug'} onClick={() => close()}/>
  </footer>
}

export const useModalControls = () => {
  const modalContext = useContext(ModalProviderContext)
  return {
    close: modalContext.close,
  }
}