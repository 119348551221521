import {
  Employee,
  EmployeeWorkDays,
  Project,
  Team,
  TeamAvailability,
  Task,
  Holiday,
  Supplier, PlanningAbsence, QuantityType, Note, Order, Workorder
} from "./dto";
import {useCallback} from "react";
import {getToken} from "../auth/AuthProvider";
import moment from "moment";
import config from "../config";
import {filterUndefinedValues} from "../util/filterUndefinedValues";

const API_BASE_URL = config.apiBaseUrl

export const useApiCall = () => {
  const checkForValidationErrors = async (response: Response): Promise<void> => {
    if (response.status === 422) {
      const json = await response.json()
      if ("message" in json && "errors" in json && typeof json.errors === "object") {
        throw new ValidationError(json.message, json.errors)
      }
      if ("message" in json) {
        throw new ValidationError(json.message, {'message': [json.message]})
      }
    }
  }

  const get = useCallback(async function<T>(url: string) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      }
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])
  const post = useCallback(async function<T>(url: string, body: object) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(body)
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])
  const del = useCallback(async function<T>(url: string) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      }
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])

  return {
    getProjects: useCallback(async (date: Date) => {
      const json = await get<object[]>(`projects?date=${Math.floor(date.getTime()/1000)}`)
      return json.data.map(Project.fromJson)
    }, [get]),
    getTasks: useCallback(async (date: Date) => {
      const json = await get<object[]>(`tasks?date=${Math.floor(date.getTime()/1000)}`)
      return json.data.map(Task.fromJson)
    }, [get]),
    getWorkorders: useCallback(async (date: Date) => {
      const json = await get<object[]>(`workorders?date=${Math.floor(date.getTime()/1000)}`)
      return json.data.map(Workorder.fromJson)
    }, [get]),
    getEmployees: useCallback(async () => {
      const json = await get<object[]>('employees')
      return json.data.map(Employee.fromJson)
    }, [get]),
    addEmployee: useCallback(async (employee: Pick<Employee, "firstName"|"lastName"|"email">) => {
      await post<void>(`employees`, employee.email === null ? {
        first_name: employee.firstName,
        last_name: employee.lastName,
      } : {
        first_name: employee.firstName,
        last_name: employee.lastName,
        email: employee.email,
      })
    }, [post]),
    getEmployeeWorkingDays: useCallback(async (employee: Employee) => {
      const json = await get<object>(`employees/${employee.id}/work_days`)
      return EmployeeWorkDays.fromJson(json.data)
    }, [get]),
    updateEmployeeWorkingDays: useCallback(async (employee: Employee, days: EmployeeWorkDays) => {
      await post<void>(`employees/${employee.id}/work_days`, {
        even_mon: days.evenMonday,
        even_tue: days.evenTuesday,
        even_wed: days.evenWednesday,
        even_thu: days.evenThursday,
        even_fri: days.evenFriday,
        even_sat: days.evenSaturday,
        even_sun: days.evenSunday,
        odd_mon: days.oddMonday,
        odd_tue: days.oddTuesday,
        odd_wed: days.oddWednesday,
        odd_thu: days.oddThursday,
        odd_fri: days.oddFriday,
        odd_sat: days.oddSaturday,
        odd_sun: days.oddSunday,
        start_at: Math.floor(new Date().getTime()/1000),
      })
    }, [post]),
    updateEmployee: useCallback(async (employee: Pick<Employee, "id"|"firstName"|"lastName"|"enabled"|"email">, archiveDate?: Date) => {
      await post<void>(`employees/${employee.id}`, {
        enabled: employee.enabled,
        first_name: employee.firstName,
        last_name: employee.lastName,
        ...(employee.email ? {email: employee.email} : {}),
        ...(archiveDate ? {archive_date: archiveDate} : {})
      })
    }, [post]),
    updateEmployeePassword: useCallback(async (employee: Pick<Employee, "id">, password: string) => {
      await post<void>(`employees/${employee.id}/password`, {
        password: password,
      })
    }, [post]),
    saveEmployeeColor: useCallback(async (employeeId: string, color: string) => {
      await post<void>(`employees/${employeeId}/metadata`, {
        color: color,
      })
    }, [post]),
    getTeamAvailability: useCallback(async (date: Date): Promise<TeamAvailability> => {
      const json = await get<TeamAvailability>(`planning/team_availability?date=${Math.floor(date.getTime()/1000)}`)
      return TeamAvailability.fromJson(json.data)
    }, [get]),
    getPlanningAbsence: useCallback(async (date: Date) => {
      const json = await get<{[day: string]: []|{[employeeId: string]: {employee_uuid: string, reason: string}}}>(`planning/absence?date=${Math.floor(date.getTime()/1000)}`)
      return PlanningAbsence.fromJsonMap(json.data)
    }, [get]),
    getTeams: useCallback(async (date: Date): Promise<Team[]> => {
      const json = await get<object[]>(`teams?date=${Math.floor(date.getTime()/1000)}`)
      return json.data.map(Team.fromJson)
    }, [get]),
    addTeam: useCallback(async (team: Pick<Team, "name">, isTemp: boolean, date?: Date) => {
      if (isTemp && date === undefined) {
        date = new Date()
      }
      await post<void>(`teams`, {
        name: team.name,
        temporary: isTemp ? 1 : 0,
        date: moment(date).format('YYYY-MM-DD'),
      })
    }, [post]),
    editTeam: useCallback(async (team: Pick<Team, "id"|"name"|"category">) => {
      await post<void>(`teams/${team.id}`, {
        name: team.name,
        category: team.category,
      })
    }, [post]),
    deleteTeam: useCallback(async (team: Team, date: Date) => {
      await post<void>(`teams/${team.id}`, {
        end_at: moment(date).format('YYYY-MM-DD'),
      })
    }, [del]),
    joinTeam: useCallback(async (team: Team, employee: Employee, date: Date, isTemporary?: boolean, reason?: string, endDate?: Date, paidVacation?: boolean) => {
      const temporary = isTemporary ?? false
      const joinReason = reason ?? null
      await post<void>(`teams/${team.id}/members`, {
        employee_id: employee.id,
        from_date: Math.floor(date.getTime()/1000),
        ...(endDate ? {end_date: Math.floor(endDate.getTime()/1000)} : {}),
        temporary: temporary?1:0,
        reason: joinReason,
        paid: paidVacation,
      })
    }, [post]),
    updateTeamsOrder: useCallback(async (teams: string[]) => {
      await post<void>(`teams/order`, {
        teams: teams,
      })
    }, [post]),
    endTeamMembership: useCallback(async (teamId: string, membershipId: string, endDate: Date) => {
      await post<void>(`teams/${teamId}/members/${membershipId}/end`, {
        end_at: moment(endDate).format('YYYY-MM-DD'),
      })
    }, [post]),
    cancelPlannedChange: useCallback(async (teamId: string, membershipId: string) => {
      await del<void>(`teams/${teamId}/members/${membershipId}`)
    }, [del]),
    addTask: useCallback(async (projectId: string, description: string, type?: string, amount?: number, note?: string) => {
      const json = await post<object[]>(`projects/${projectId}/tasks`, {
        task_name: description,
        ...(type !== undefined ? {quantity_type: type} : {}),
        ...(amount !== undefined ? {quantity_amount: amount} : {}),
        ...(note !== undefined ? {note: note} : {})
      })
      return Task.fromJson(json.data[0])
    }, [post]),
    addTasks: useCallback(async (projectId: string, descriptions: string[], type?: string, amount?: number, note?: string) => {
      const json = await post<object[]>(`projects/${projectId}/tasks`, {
        task_names: descriptions,
        ...(type !== undefined ? {quantity_type: type} : {}),
        ...(amount !== undefined ? {quantity_amount: amount} : {}),
        ...(note !== undefined? {note: note} : {})
      })
      return json.data.map(t => Task.fromJson(t))
    }, [post]),
    planTask: useCallback(async (taskId: string, teamId: string|null, startAt: Date, durationMinutes: number) => {
      await post<object[]>(`tasks/${taskId}`, {
        start_at: moment(startAt).format(),
        duration: durationMinutes,
        team_uuid: teamId,
      })
    }, [post]),
    unPlanTask: useCallback(async (taskId: string) => {
      await post<object[]>(`tasks/${taskId}`, {
        start_at: null
      })
    }, [post]),
    taskToBuffer: useCallback(async (taskId: string, date: Date) => {
      await post<object[]>(`tasks/${taskId}`, {
        start_at: moment(date).format(),
      })
    }, [post]),
    batchPlanTask: useCallback(async (taskId: string, dates: Date[]) => {
      const json = await post<{ failed: {[date: string]: string} }>(`tasks/${taskId}/batch`, {
        dates: dates.map(d => moment(d).format('Y-MM-DD')),
      })
      return json.data.failed
    }, [post]),
    updateTaskColor: useCallback(async (taskId: string, color: string) => {
      await post<object[]>(`tasks/${taskId}`, {
        color,
      })
    }, [post]),
    updateTaskDescriptionAndQuantity: useCallback(async (taskId: string, description: string, quantityType: string, quantityAmount: number) => {
      await post<object[]>(`tasks/${taskId}`, {
        task_name: description,
        quantity_type: quantityType,
        quantity_amount: quantityAmount,
      })
    }, [post]),
    updateTaskInvoicedState: useCallback(async (taskId: string, isInvoiced: boolean) => {
      await post<object[]>(`tasks/${taskId}`, {
        is_invoiced: isInvoiced,
      })
    }, [post]),
    updateTaskMetadata: useCallback(async (taskId: string, fields: Partial<{workorderRemark: string|null, projectRemark: string|null, todos: {[text: string]: boolean}}>) => {
      await post<object[]>(`tasks/${taskId}`, {
        ...(fields.workorderRemark !== undefined ? {workorder_remark: fields.workorderRemark} : {}),
        ...(fields.projectRemark !== undefined ? {project_remark: fields.projectRemark} : {}),
        ...(fields.todos !== undefined ? {todos: fields.todos} : {}),
      })
    }, [post]),
    deleteTask: useCallback(async (task: Task) => {
      await del<void>(`tasks/${task.id}`)
    }, [del]),
    getGlobalHolidays: useCallback(async (date: Date): Promise<Holiday[]> => {
      const json = await get<object[]>(`planning/global_holidays?date=${Math.floor(date.getTime()/1000)}`)
      return json.data.map(Holiday.fromJson)
    }, [get]),
    addGlobalHoliday: useCallback(async (dates: Date[], description: string, type: string) => {
      await post<void>(`planning/global_holidays`, {
        dates: dates.map(d => moment(d).format('Y-MM-DD')),
        name: description,
        type: type,
      })
    }, [post]),
    deleteGlobalHoliday: useCallback(async (holiday: Holiday) => {
      await del<void>(`planning/global_holidays/${holiday.id}`)
    }, [del]),
    addQuantityType: useCallback(async (name: string, enabled: boolean, defaultColorId: string|null, descriptionTemplate: string[]|null, hideArea: boolean) => {
      const template = descriptionTemplate?.join('') === '' ? null : descriptionTemplate
      await post<void>(`quantities/types`, {
        name: name,
        enabled: enabled,
        default_color: defaultColorId,
        description_template: template,
        hide_area: hideArea,
      })
    }, [post]),
    editQuantityType: useCallback(async (id: string, name: string, enabled: boolean, defaultColorId: string|null, descriptionTemplate: string[]|null, hideArea: boolean) => {
      const template = descriptionTemplate?.join('') === '' ? null : descriptionTemplate
      await post<void>(`quantities/types/${id}`, {
        name: name,
        enabled: enabled,
        default_color: defaultColorId,
        description_template: template,
        hide_area: hideArea,
      })
    }, [post]),
    getSuppliers: useCallback(async () => {
      const json = await get<object[]>(`suppliers`)
      return json.data.map(Supplier.fromJson)
    }, [get]),
    addSupplier: useCallback(async (supplier: Pick<Supplier, "name">) => {
      await post<void>(`suppliers`, {
        name: supplier.name,
      })
    }, [post]),
    editSupplier: useCallback(async (supplier: Pick<Supplier, "id"|"name">) => {
      await post<void>(`suppliers/${supplier.id}`, {
        name: supplier.name,
      })
    }, [post]),
    addTaskSupplier: useCallback(async (taskId: string, supplierId: string, description: string|null, status: "green"|"orange"|"red") => {
      await post<void>(`tasks/${taskId}/suppliers`, {
        supplier_id: supplierId,
        status: status,
        ...(description !== null ? {description} : {}),
      })
    }, [post]),
    updateTaskSupplierStatus: useCallback(async (taskId: string, supplierId: string, status: "green"|"orange"|"red") => {
      await post<void>(`tasks/${taskId}/suppliers/${supplierId}`, {
        status: status,
        is_complete: status === "green",
      })
    }, [post]),
    // getTaskSuppliers: useCallback(async (taskId: string): Promise<TaskSupplier[]> => {
    //   const json = await get<object[]>(`tasks/${taskId}/suppliers`)
    //   return json.data.map(TaskSupplier.fromJson)
    // }, [get])
    updateTaskSupplierDescription: useCallback(async (taskId: string, supplierId: string, status: string, description: string|null) => {
      await post<void>(`tasks/${taskId}/suppliers/${supplierId}`, {
        description: description,
        status: status,
      })
    }, [post]),
    deleteTaskSupplier: useCallback(async (taskId: string, supplierId: string) => {
      await del<void>(`tasks/${taskId}/suppliers/${supplierId}`)
    }, [del]),

    // Quantities
    getQuantityTypes: useCallback(async () => {
      const json = await get<{name: string, gradient: number|null, intercept: number|null}[]>('quantities/types')
      return json.data.map(QuantityType.fromJson)
    }, [get]),

    // Colors
    getColors: useCallback(async () => {
      const json = await get<{id: string,name: string,enabled: boolean,color: string,colorName: string}[]>('colors')
      return json.data
    }, [get]),
    addColor: useCallback(async (name: string, enabled: boolean, color: string) => {
      const json = await post<{id: string, name: string, enabled: boolean, color: string, colorName: string}[]>('colors', {
        name: name,
        enabled: enabled,
        color: color
      })
      return json.data
    }, [post]),
    updateColor: useCallback(async (id: string, name: string, enabled: boolean, color: string) => {
      const json = await post<{id: string, name: string, enabled: boolean, color: string, colorName: string}[]>(`colors/${id}`, {
        name: name,
        enabled: enabled,
        color: color
      })
      return json.data
    }, [post]),

    // Orders
    getOrders: useCallback(async () => {
      const json = await get<{id: string,date: string, text: string, project_id: number, amount: number, quantity: number, quantity_unit: string, is_done: boolean, remark: string}[]>('orders')
      return json.data.map(j => Order.fromJson(j))
    }, [get]),
    addOrder: useCallback(async (projectId: number, date: Date, text: string, amount?: number, quantity?: number, quantityUnit?: string, remark?: string) => {
      const json = await post<{id: string,date: string, text: string, project_id: number, amount: number, quantity: number, quantity_unit: string, is_done: boolean, remark: string}>('orders', filterUndefinedValues({
        project_id: projectId,
        date: moment(date).format('YYYY-MM-DD'),
        text: text,
        amount: amount,
        quantity: quantity,
        quantity_unit: quantityUnit,
        remark: remark,
      }))
      return Order.fromJson(json.data)
    }, [post]),
    updateOrder: useCallback(async (id: string, projectId?: number, date?: Date, text?: string, amount?: number, quantity?: number, quantityUnit?: string, remark?: string, isDone?: boolean) => {
      const json = await post<{id: string,date: string, text: string, project_id: number, amount: number, quantity: number, quantity_unit: string, is_done: boolean, remark: string}>(`orders/${id}`, filterUndefinedValues({
        project_id: projectId,
        date: date === undefined ? undefined : moment(date).format('YYYY-MM-DD'),
        text: text,
        amount: amount,
        quantity: quantity,
        quantity_unit: quantityUnit,
        remark: remark,
        is_done: isDone,
      }))
      return Order.fromJson(json.data)
    }, [post]),
    deleteOrder: useCallback(async (id: string) => {
      await del(`orders/${id}`)
    }, [post]),

    getNotes: useCallback(async (date: Date) => {
      const json = await get<object[]>(`notes?date=${Math.floor(date.getTime()/1000)}`)
      return json.data.map(Note.fromJson)
    }, [get]),
    editNote: useCallback(async (date: Date, text: string) => {
      const json = await post<object>('notes', {
        date: moment(date).format('YYYY-MM-DD'),
        text: text,
      })
      return Note.fromJson(json.data)
    }, [post]),
    sendWorkorders: useCallback(async (taskIds: string[]) => {
      await post<void>('workorders', {
        task_ids: taskIds,
      })
    }, [post]),
    archiveWorkOrder: useCallback(async (workorderId: string) => {
      await post<void>(`workorders/${workorderId}/archive`, {
        archived_at: moment(Date.now()).format('YYYY-MM-DD'),
      })
    }, [post]),
    unarchiveWorkOrder: useCallback(async (workorderId: string) => {
      await post<void>(`workorders/${workorderId}/archive`, {
        archived_at: null,
      })
    }, [post]),
  }
}

export class ValidationError extends Error {
  constructor(public readonly message: string, public readonly errors: { [key: string]: string[] }) {
    super(message);
  }
}